import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				The Copper Stein
			</title>
			<meta name={"description"} content={"Crafted Libations, Unforgettable Moments"} />
			<meta property={"og:title"} content={"The Copper Stein"} />
			<meta property={"og:description"} content={"Crafted Libations, Unforgettable Moments"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65773594fdcae00021dadf60/images/close-up-man-pouring-coffee-glass%20%281%29.jpg?v=2023-12-11T18:26:16.280Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10:25:49.524Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10:25:49.524Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10:25:49.524Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10:25:49.524Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10:25:49.524Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10:25:49.524Z"} />
		</Helmet>
		<Components.Header1>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Header1>
		<Section padding="100px 0 100px 0" md-padding="80px 0 90px 0" quarkly-title="Hero-10">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
				sm-align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="--headline1"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="--darkL1"
					lg-padding="0px 0 0px 0"
					md-font="normal 700 42px/1.2 --fontFamily-sansTrebuchet"
				>
					EventExcellence'e Hoş Geldiniz
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					color="--dark"
					padding="0px 200px 0px 200px"
					text-align="center"
					lg-padding="0px 0 0px 0"
					md-font="normal 400 22px/1.5 --fontFamily-sansHelvetica"
				>
					EventExcellence'e hoş geldiniz, burada etkinlik hayalleriniz gerçeğe dönüşüyor. Ekip olarak, her ayrıntının kusursuz bir şekilde gerçekleştirildiğinden emin olarak, kişiye özel etkinlikler düzenlemekte uzmanız. İster kurumsal bir toplantı, ister düğün ya da özel bir kutlama planlıyor olun, profesyonel kadromuz her ayrıntıyı özenle ele alır.
				</Text>
				<Button
					margin="0px 0 20px 0px"
					padding="14px 35px 14px 35px"
					background="--color-darkL1"
					font="normal 400 17px/1.5 --fontFamily-sans"
					sm-margin="0px 0 15px 0px"
					transition="background-color 0.2s ease 0s"
					border-width="2px"
					border-style="solid"
					border-color="#3f24d8"
					hover-color="--darkL1"
					hover-background="rgba(63, 36, 216, 0)"
					sm-width="100%"
					href="/about"
				>
					Hakkımızda ve Hizmetlerimiz
				</Button>
			</Box>
			<Image
				src="https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-1.jpg?v=2024-08-01T10:25:49.521Z"
				display="block"
				height="600px"
				object-fit="cover"
				border-radius="35px"
				md-height="400px"
				sm-height="300px"
				object-position="top"
				srcSet="https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-1.jpg?v=2024-08-01T10%3A25%3A49.521Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-1.jpg?v=2024-08-01T10%3A25%3A49.521Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-1.jpg?v=2024-08-01T10%3A25%3A49.521Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-1.jpg?v=2024-08-01T10%3A25%3A49.521Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-1.jpg?v=2024-08-01T10%3A25%3A49.521Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-1.jpg?v=2024-08-01T10%3A25%3A49.521Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-1.jpg?v=2024-08-01T10%3A25%3A49.521Z&quality=85&w=3200 3200w"
				sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
			/>
		</Section>
		<Section padding="100px 0 100px 0" md-padding="80px 0 90px 0" quarkly-title="Hero-10">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
				sm-align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="--headline1"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="--darkL1"
					lg-padding="0px 0 0px 0"
					md-font="normal 700 42px/1.2 --fontFamily-sansTrebuchet"
				>
					EventExcellence'e Hoş Geldiniz
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					color="--dark"
					padding="0px 200px 0px 200px"
					text-align="center"
					lg-padding="0px 0 0px 0"
					md-font="normal 400 22px/1.5 --fontFamily-sansHelvetica"
				>
					EventExcellence'e hoş geldiniz, burada etkinlik hayalleriniz gerçeğe dönüşüyor. Ekip olarak, her ayrıntının kusursuz bir şekilde gerçekleştirildiğinden emin olarak, kişiye özel etkinlikler düzenlemekte uzmanız. İster kurumsal bir toplantı, ister düğün ya da özel bir kutlama planlıyor olun, profesyonel kadromuz her ayrıntıyı özenle ele alır.
				</Text>
				<Button
					margin="0px 0 20px 0px"
					padding="14px 35px 14px 35px"
					background="--color-darkL1"
					font="normal 400 17px/1.5 --fontFamily-sans"
					sm-margin="0px 0 15px 0px"
					transition="background-color 0.2s ease 0s"
					border-width="2px"
					border-style="solid"
					border-color="#3f24d8"
					hover-color="--darkL1"
					hover-background="rgba(63, 36, 216, 0)"
					sm-width="100%"
					href="/about"
				>
					Hakkımızda ve Hizmetlerimiz
				</Button>
			</Box>
			<Image
				src="https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-1.jpg?v=2024-08-01T10:25:49.521Z"
				display="block"
				height="600px"
				object-fit="cover"
				border-radius="35px"
				md-height="400px"
				sm-height="300px"
				object-position="top"
				srcSet="https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-1.jpg?v=2024-08-01T10%3A25%3A49.521Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-1.jpg?v=2024-08-01T10%3A25%3A49.521Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-1.jpg?v=2024-08-01T10%3A25%3A49.521Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-1.jpg?v=2024-08-01T10%3A25%3A49.521Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-1.jpg?v=2024-08-01T10%3A25%3A49.521Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-1.jpg?v=2024-08-01T10%3A25%3A49.521Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-1.jpg?v=2024-08-01T10%3A25%3A49.521Z&quality=85&w=3200 3200w"
				sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
			/>
		</Section>
		<Section
			padding="0 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--darkL1"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Neden EventExcellence?
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
						Kişiselleştirilmiş Hizmet
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Vizyonunuzu ve hedeflerinizi anlayarak, etkinliğinizi tam olarak sizin taleplerinize göre şekillendiriyoruz.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Deneyimli Ekip
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Yılların etkinlik planlama deneyimini beraberinde getiren ekibimiz, çeşitli etkinlik türlerinde başarıya ulaşmıştır.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Yaratıcılık ve Yenilikçilik
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Yaratıcılık ve yenilikçilik tutkumuzla, her zaman benzersiz ve unutulmaz deneyimler sunmayı hedefliyoruz.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" background="--color-lightD2" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text
							margin="0px 0px 15px 0px"
							font="--headline2"
							text-align="left"
							lg-font="normal 600 36px/1.2 --fontFamily-sans"
							color="--light"
						>
							EventExcellence Hakkında
						</Text>
						<Text
							margin="0px 0px 50px 0px"
							font="--base"
							lg-margin="0px 0px 40px 0px"
							sm-margin="0px 0px 30px 0px"
							color="--light"
						>
							EventExcellence, eşsiz etkinlikler yaratma tutkusuyla ve olağanüstü hizmet anlayışıyla kuruldu. Vizyonunuzu gerçeğe dönüştürmek ve her etkinliği unutulmaz kılmak için buradayız. İster kurumsal etkinlikler, ister özel kutlamalar olsun, her projede mükemmellik sunmayı taahhüt ediyoruz.
						</Text>
					</Box>
					<Image
						src="https://images.unsplash.com/photo-1588974328642-c4a1857fee9d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
						srcSet="https://images.unsplash.com/photo-1588974328642-c4a1857fee9d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1588974328642-c4a1857fee9d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1588974328642-c4a1857fee9d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1588974328642-c4a1857fee9d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1588974328642-c4a1857fee9d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1588974328642-c4a1857fee9d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1588974328642-c4a1857fee9d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Contact>
			<Override slot="link" />
		</Components.Contact>
		<Components.NewFooter1>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text" />
		</Components.NewFooter1>
		<Components.Terms>
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Terms>
	</Theme>;
});